.profile-menu-container {
  float: right;
  font-weight: 300;
  text-transform: none;
  margin-right: 10px;

  p {
    margin-top: 0;
    text-align: right;
  }

  .action-buttons {
    float: right;

    button {
      margin-left: 10px;
    }
  }
}

.custom-menu-button {
  display: flex;
  align-items: center;
  padding: 40px 0;
  height: 20px;

  .avatar {
    cursor: pointer;
    padding: 25px;
    position: absolute;
  }
}

.page-mask {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 105px;
  height: 1000vh;
  width: 1000vw;
  z-index: 1;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(1px);
  pointer-events: none;
  transition: .15s linear background-color;
}