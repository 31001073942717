.details-action-box {
  border: 1px solid gray;
  padding: 10px;
}

.share-box {
  .MuiPaper-rounded {
    border-color: transparent;
  }

  .MuiPaper-root {
    background-color: #f4f1eb;
  }
}

.details-tool-bar {
  * {
    padding: 3px;
  }
}

.share-form {
  margin: auto;
  padding: 0 10px;
  display: inline-flex;
  flex-direction: row;

  .share-component-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 10px 0;

    .MuiFormLabel-root {
      font-weight: 600;
      color: black;
      padding-bottom: 5px;
    }

    .MuiFilledInput-input {
      padding: 7px 12px 10px;
      background-color: white;
    }

    .MuiFilledInput-multiline {
      padding: 0;
      background-color: white;
    }
  }

  .share-bottom-area {
    overflow: hidden;
    
    .send-status {
      float: left;
      padding: 17px 0;

      .message-label {
        display: inline-flex;

        * {
          vertical-align: middle;
          padding-right: 3px;
        }
      }
    }
  
    .send-actions {
      padding: 10px 0;
      float: right;
  
      .send-button {
        padding: 10px 40px;
      }
    }
  }
}

