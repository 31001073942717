@import '../../globals/styles/global_style';

.details-page {
  .death-notice-details {
    padding: 30px 0;

    @include main-page-margin;

    .details-portion {
      margin: 10px 0px;
    }

    .title-text-field {
      width: 100%;
      font-size: 2.215rem;
    }
    .margin-text-field {
      margin-top: 15px;
    }
  }
}
