@keyframes growDown {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}

@keyframes growUp {
  0% {
    transform: rotateX(0deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(-90deg)
  }
}