@import '../../globals/styles/global_color';

.search-form {
  .MuiButton-text {
    text-transform: none;
  }
  
  .MuiInputBase-input {
    background-color: white;
  }

  .search-bar-content {
    padding: 35px 110px;
    background: #f4f1eb;

    .MuiOutlinedInput-root {
      background: white;
    }
  }

  .search-bar-filter-content {
    padding: 10px 110px;
  }

  .date-filter-content {
    padding: 20px 110px;
    overflow: hidden;
  }

  .header-left {
    float: left;
  }

  .header-right {
    float: right;
    position: relative;
    align-items: center;

    .MuiToggleButton-root {
      border-color: transparent;
      padding: 10px;
      margin: 5px;
    }

    .specific-year-input {
      width: 80px;

      .MuiOutlinedInput-input {
        padding: 10px;
      }
    }

    .MuiFormControl-root {
      vertical-align: middle;
    }
  }

  .advanced-search-content {
    margin: 0 110px;
  }

  .action-content {
    padding: 50px 110px;

    .action-left {
      display: inline-flex;
  
      * {
        margin-right: 5px;
      }

      a, a:visited {
        text-decoration: none;
        color: lighten($header-color, 20%);
        * {
          font-weight: 800;
        }
      }
    }
  
    .action-right {
      display: inline-flex;

      .new-search-button {
        margin-right: 30px;
      }
  
      .search-button {
        padding: 10px 50px;
      }
    }
  }
  
}