.date-picker-content {
  padding: 10px 0;
  
  .MuiOutlinedInput-input {
    padding: 10px;
  }

  .MuiOutlinedInput-root {
    @media screen and (max-width: 1160px) {
      width: 120px;
    }
  }
}