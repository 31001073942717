.pagination-toolbar {

  margin: 20px 20px;

  .header-left {
    float: left;
  }

  .header-right {
    float: right;
    margin-top: -4px;
  }

  .pagination-sort-tools {
    .sort-tools-dropdown {
      width: 150px;
    }

    .MuiOutlinedInput-input {
      padding-top: 12px;
      padding-bottom: 10px;
    }
  }

  .label {
    display: flex;
    align-items: center;
  }
}
