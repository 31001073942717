
.pdf-title {
  padding: 24px 110px;
  background: #f4f1eb;

  .pub-date-picker {
    position: relative;
    top: 10px;
  }
}

.search-button {
  margin-top: 10px;
}

.pdf-body {
  padding: 45px 110px;
}

.folder-pdf {
  padding-left: 15px;
}

.folder-pdf-container {
  background-color: #f4f1eb;
}

.file-pdf {
  padding-left: 65px;
}

.pdf-viewer-container {
  padding: 10px;
}

.pdf-files-container {
  padding: 6px;
}
