@import '../../globals/styles/global_style';

.results-page {
  .search-information-background {
    background-color: #f4f1eb;
  }

  .search-information-content {
    padding: 38px 20px;
    @include main-page-margin;
  }

  .search-result-pagination {
    @include main-page-margin;
  }

  .search-result-list {
    @include main-page-margin;
  }

  .search-result-actions {
    padding: 50px;
    margin: auto;
    width: 50%;

    .more-results-button {
      display: block;
      padding: 10px 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .error-page {
    margin-top: 75px;

    display: flex;
    justify-content: center;
  }
}