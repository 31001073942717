.result-thumbnail {
  width: 140px;
  margin: auto;

  @media screen and (max-width: 1100px) {
    width: 100px;
  }

  @media screen and (max-width: 700px) {
    width: 50px;
  }
}

.result-fields {
  * {
    padding-bottom: 3px;
  }
}

.search-results {
  padding-top: 50px;
}

.edit-button {
  margin-left: 10px;
}

.disabled-label {
  color: #6b6b6a;
}