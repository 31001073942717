@import '../../globals/styles/global_style';

.details-page {
  .story-details {
    padding: 30px 0;

    @include main-page-margin;

    .details-portion {
      margin: 10px 0px;
    }

    .title-text-field {
      width: 100%;
      margin-top: 15px;
    }

    .display-field:hover {
      background: #fbfbfb;
      transition: 300ms;
    }

    .header-title {
      * {
        margin-bottom: 10px;
      }
    }
  }
}