.query-mode-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .query-mode-paper {
    background-color: white;
    box-shadow: 0 8px 6px -6px black;
    padding: 30px;

    .query-mode-content {
      padding-top: 10px;

      .content-action {
        float: right;
      }

      .query-mode-area {
        width: 100%;
        margin: 1px;

        .MuiFilledInput-multiline {
          padding: 10px;
          font-family: monospace;
        }
      }
    }

    .modal-actions {
      padding-top: 20px;
      float: right;

      .modal-button {
        margin-left: 20px;
      }
    }
  }
}