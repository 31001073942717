@import '../../globals/styles/global_style';
@import '../../globals/styles/global_color';
@import '../../globals/styles/global_animation';

header {
  .main-header {
    background: $header-color;
    color: white;
    overflow: hidden;
  }

  .header-left {
    display: flex;
    float: left;
    font-size: 1.5rem;
    padding-top: -10px;

    @include main-page-margin;
  }

  .header-right {
    display: flex;
    float: right;

    @include main-page-margin;

    .header-menu-button {
      font-weight: 500;
    }
  }

  .header-menu-button {
    display: flex;
    align-items: center;
    padding: 40px 15px;
    color: white;
    text-decoration: none;
    height: 20px;
    text-transform: uppercase;
  }

  .header-dropdown {
    top: 104px;
    left: 0;
    width: 100%;
    height: 125px;
    position: absolute;
    background: rgb(224,129,132);
    background: linear-gradient(4deg, rgba(224,129,132,1) 0%, rgba(89,50,152,1) 28%, rgba(2,41,98,1) 47%, rgba(1,30,71,1) 65%, rgba(1,30,71,1) 100%);
    box-shadow: 0px 15px 20px rgb(44, 44, 44);
  }
  
  .drop-animated-activated {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s;
    transform-origin: top center;
  }

  .content {
    margin-right: 30px;
  }

  .drop-animated {
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  .header-hover-effect:after {
    display:block;
    content: '';
    border-bottom: solid 5px white;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
  }

  .selected:after {
    transform: scaleX(1);  
  }

  .header-hover-effect:hover:after {
    transform: scaleX(1);
  }
}