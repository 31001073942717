@import '../../globals/styles/global_style';

.details-page {
  .left-area {
    flex-basis: 80%;
    padding: 10px;
  }

  .right-area {
    flex-basis: 400px;
    padding: 10px;
  }

  .pdf-details {
    display: flex;
    padding: 30px 0;

    @include main-page-margin;

    .details-portion {
      margin: 15px 0px;
    }

    .iframeMargin {
      margin-top: 15px;
    }
  }
}