.advanced-search {
  .expand-icon {
    margin-left: auto;
    transition: transform 0.2s linear;
  }
  
  .expand-open {
    transform: rotate(0deg);
  }

  .expand-close {
    transform: rotate(-90deg);
  }

  .card-header {
    display: inline-flex;
    vertical-align: middle;
    * {
      margin-right: 10px;
    }
  }
  
  .advanced-search-collapsed-content {
    flex-direction: column;
    margin-left: 35px;
  }

  .MuiFormControl-root {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .advanced-search-label {
      width: 200px;
    }
    
    .advanced-search-field {
      .MuiOutlinedInput-root {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        padding: 10px;
      }
    }
    
    .MuiFormControlLabel-root {
      margin-left: -10px;
    }

    .MuiFormGroup-root {
      flex-direction: row;
    }
  }
}
