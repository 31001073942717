@import '../../globals/styles/global_style';


.details-page {
  .photo-details {
    padding: 30px 0;

    @include main-page-margin;

    .details-portion {
      margin: 10px 0px;
    }
  }
}