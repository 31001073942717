.section-title {
  padding: 25px 135px;
  background: #f4f1eb;
}

.section-button {
  margin-top: 70px;
}

.section-body {
  padding: 15px 135px;
}

.section-select-box {
  min-width: 50px;
}

.loading-modal {
  padding: 30px;
}
